<template>
  <div>
    <List :isFour="isFour" chanpin="chanpin" :list="chanpinList"></List>
    <List :isFour="isFour" anli="anli" :list="anliList"></List>
    <div style="width: 100%; height: 80px; background-color: 80px"></div>
  </div>
</template>

<script>
import List from "@/components/List.vue";
export default {
  components: {
    List,
  },
  data() {
    return {
      isFour: true,
      anliList: [
        {
          pic: "http://files.sxcfx.cn/upload/20220304/ad312bb1ac398833b7aa52ca57ccda14.png",
          title: "财付行小程序",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/79671580321bb9675e889b38b5422660.png",
          title: "中国福利彩票",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/fa348ca7ed46b723c9715b76c1bcf81f.png",
          title: "中国电信加油优惠券",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/746bf35fc4980511a5a0bd59db7966b2.png",
          title: "招商银行签到有礼",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/ad312bb1ac398833b7aa52ca57ccda14.png",
          title: "财付行小程序",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/79671580321bb9675e889b38b5422660.png",
          title: "中国福利彩票",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/fa348ca7ed46b723c9715b76c1bcf81f.png",
          title: "中国电信加油优惠券",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/746bf35fc4980511a5a0bd59db7966b2.png",
          title: "招商银行签到有礼",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/ad312bb1ac398833b7aa52ca57ccda14.png",
          title: "财付行小程序",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/79671580321bb9675e889b38b5422660.png",
          title: "中国福利彩票",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/fa348ca7ed46b723c9715b76c1bcf81f.png",
          title: "中国电信加油优惠券",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/746bf35fc4980511a5a0bd59db7966b2.png",
          title: "招商银行签到有礼",
        },
      ],
      chanpinList: [
        {
          pic: "http://files.sxcfx.cn/upload/20220304/ad312bb1ac398833b7aa52ca57ccda14.png",
          title: "财付行小程序",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/79671580321bb9675e889b38b5422660.png",
          title: "中国福利彩票",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/fa348ca7ed46b723c9715b76c1bcf81f.png",
          title: "中国电信加油优惠券",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/746bf35fc4980511a5a0bd59db7966b2.png",
          title: "招商银行签到有礼",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/ad312bb1ac398833b7aa52ca57ccda14.png",
          title: "财付行小程序",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/79671580321bb9675e889b38b5422660.png",
          title: "中国福利彩票",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/fa348ca7ed46b723c9715b76c1bcf81f.png",
          title: "中国电信加油优惠券",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/746bf35fc4980511a5a0bd59db7966b2.png",
          title: "招商银行签到有礼",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/ad312bb1ac398833b7aa52ca57ccda14.png",
          title: "财付行小程序",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/79671580321bb9675e889b38b5422660.png",
          title: "中国福利彩票",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/fa348ca7ed46b723c9715b76c1bcf81f.png",
          title: "中国电信加油优惠券",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/746bf35fc4980511a5a0bd59db7966b2.png",
          title: "招商银行签到有礼",
        },
      ],
    };
  },
};
</script>

<style>
</style>